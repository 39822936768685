@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  color: #262626;
  height: 100%;
}
.Toastify__toast{
  padding:0px !important;
  border-radius:100px !important;
  height:48px !important;
  min-height:48px;
  top:56px;
}
.Toastify__toast-body {
  flex: initial;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  padding:0 20px;
}
.Toastify__toast-icon{
  margin-right:6px;
  margin-bottom:4px;
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  border: 1px solid #d4d4d4;
  border-radius: 6px;
  overflow:hidden;
  display:flex;
  align-items:center;
}
.react-datepicker__input-container input {
  border: none;
  padding: 12px;
  font-size: 16px;
  width: 100%;
}
.react-datepicker__input-container input::placeholder {
  color: #D4D4D4;
}

.react-datepicker {
  font-size: .8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  position: relative;
  display:flex;
}

.react-datepicker__header {
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #aeaeae;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__current-month {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 14px;
  padding: 0 12px;
  border-radius: 4px;
  font-weight: 500;
  flex: 1 1 0%;
  text-align: center;
  color: #000;
}
.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 14px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__triangle{
  display:none;
}

@media (max-width: 767px) {
  .react-datepicker__input-container input {
    font-size: 14px;
  }
}

/**
*
* react-select 디자인 override
*
*/
.react-select-container {
  border: none;
  box-sizing: border-box;
}

.react-select-container .react-select__control {
  border: 1px solid #d4d4d4;
  border-radius: 0.5rem;
}

/* is-focused, menu is-open, hover */
.react-select-container .react-select__control--is-focused,
.react-select-container .react-select__control.react-select__control--is-focused.react-select__control--menu-is-open,
.react-select-container .react-select__control--is-hover,
.react-select-container .react-select__control:hover {
  border: 1px solid #737373;
  box-shadow: none;
  background: #fff;
}

.react-select-container .react-select__value-container {
  padding: 12px;
  font-size: 14px;
  line-height: 24px;
  color: #222222;
}

.react-select-container .react-select__value-container {
  position: relative;
}

.react-select__value-container>div {
  height: 20px !important;
  box-sizing: border-box !important;
  margin: 0 2px !important;
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

#react-select-2-input {
  box-shadow: none !important;
}

.react-select-container .react-select__value-container .react-select__placeholder {
  color: #222222;
}

.react-select-container .react-select__control .react-select__indicators .react-select__indicator-separator.css-1okebmr-indicatorSeparator {
  width: 0;
}

.react-select__indicator-separator {
  display: none;
}

.react-select-container .react-select__control .react-select__indicators .react-select__indicator.react-select__dropdown-indicator {
  color: #222222;
  padding-right: 12px;
}

.react-select-container .react-select__menu {
  width: 100%;
  border: 1px solid #d4d4d4;
  border-radius: 0.5rem;
  left: -1px;
  box-sizing: border-box;
  box-shadow: none;
  overflow: hidden;
}

.react-select__menu-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.react-select__menu-list::-webkit-scrollbar {
  width: 10px;
  height: 100px;
}

.react-select__menu-list::-webkit-scrollbar-track {
  margin-top: 8px;
  margin-bottom: 8px;
  background: #fff;
}

.react-select__menu-list::-webkit-scrollbar-thumb {
  background: #D4D4D4;
  border-radius: 2px;
  border-right: 8px white solid;
  background-clip: padding-box;
}

.react-select__menu .react-select__menu-list div:not(:last-child)>div {
  border-bottom: 1px solid #D4D4D4;
  box-sizing: border-box;
}

.react-select-container.react-select--is-disabled .react-select__control.react-select__control--is-disabled .react-select__placeholder {
  color: #737373;
}

.react-select__menu-list .react-select__menu-notice--no-options {
  padding: 16px;
  text-align: left;
  font-size: 14px;
  color: #737373;
}

@media all and (max-width: 768px) {
  .react-select__menu-list .react-select__menu-notice--no-options {
    padding: 12px 16px;
    font-size: 12px;
  }
}

@media all and (max-width:1023px) {
  .react-select-container {
    width: 100%;
    flex-shrink: 0;
  }

  .react-select-container .react-select__value-container {
    padding: 8px;
  }

  .react-select-container .react-select__control .react-select__indicators .react-select__indicator.react-select__dropdown-indicator {
    padding-right: 13px;
  }
}